<vass-loading></vass-loading>
<!-- ROUTER -->
<div scrollToTopOnNavigate class="content">
	<router-outlet></router-outlet>
</div>

<!-- ROUTER END -->
<vass-dynamic-modal
	*ngIf="isViewModal$ | async"
	[icon]="propertiesToRender.icon"
	[title]="propertiesToRender.title | translate"
	[subtitle]="propertiesToRender.subtitle"
	[button]="propertiesToRender.button"
/>

