import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { environment as ENV, environment } from "@environments/environment";
import { EncryptionService } from "../services/EncryptionService/encryption-service.service";
import { EXCLUDE_API_ENCRYPT, FORCE_API_ENCRYPT } from "@environments/api-url";

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {
  private readonly apiUrl = ENV.api;

  constructor(private readonly ecyService: EncryptionService) {}

  /*
   * Interceptamos todas la solicitudes HTTP
   * dependiendo el tipo de peticion lo apiUrliamos a una funcion especifica
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isGetReq = req.method == "GET";
    const reqMethod = req.method == "POST" || req.method == "PUT" || req.method == "DELETE" || isGetReq;
    const isToApi = req.url.includes(environment.api);
    const isInBlackList = EXCLUDE_API_ENCRYPT.includes(req.url);
    const forceHybridEncript = FORCE_API_ENCRYPT.includes(req.url)
    if ((reqMethod && isToApi && !isInBlackList)) {
      if(!isGetReq || forceHybridEncript){
        console.log("ENCRIPTADO != POST", req.url)
        return this.handleEncryptedRequest(req, next);
      }
      console.log("ENCRIPTADO GET", req.url)
      return this.handleEncryptedGetRequest(req, next);
    } else {
      console.log("NORMAL REQUEST", req.url)
      return this.handleRegularRequest(req, next);
    }
  }

  /*
   * Aqui encriptamos el cuerpo de la peticion
   * apiUrliamos la peticion encriptada hacia el handleEncryptedResponse
   */
  private handleEncryptedRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const locKey: string = this.ecyService.generateRandomKey();
    const cryLocKey: string = this.ecyService.encryptRSA(locKey);
    const cryData: string = this.ecyService.encryptHybridBody(req.body, locKey);
    const headers = {
      "key-sync-random": cryLocKey,
    };
    const clonedReq = req.clone({ body: { data: cryData }, setHeaders: {...headers} });
    return next.handle(clonedReq).pipe(
      switchMap((event: HttpEvent<any>) => this._processHybridResponse(event)),
      catchError((error) =>
        this.handleHybridErrorResponse(error)
      )
    );
  }

  private handleEncryptedGetRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //const [ url , params] = req.url.split("?");
    const locKey: string = this.ecyService.generateRandomKey();
    const cryLocKey: string = this.ecyService.encryptRSA(locKey);
    //const cryData: string = this.ecyService.encryptBody(params, locKey);
    const headers = {
      "key-sync-random": cryLocKey,
    };
    //const newUrl = `${url}?data=${cryData}`
    //const clonedReq = req.clone({ setHeaders: { ...headers } });
    //const clonedReq = req.clone({ url: newUrl , setHeaders: { ...headers } });
    return next.handle(req).pipe(
      switchMap((event: HttpEvent<any>) => this._processAesResponse(event)),
      catchError((error) =>
        this.handleAesErrorResponse(error)
      )
    );
  }

  /*
   * Desencripta el cuerpo de la respuesta usando decryptMessage
   * Devuelve la respuesta desencriptada.
   */
  private decryptHybridResponseBody(
    event: HttpResponse<any>
  ): Observable<HttpEvent<any>> {
    const sanitizeData: any = this.ecyService.decryptHybridBody(event.body.data, event.url);
    let responseBody;
    try {
      responseBody = JSON.parse(sanitizeData);
    } catch (e) {
      responseBody = sanitizeData;
    }
    const clonedEvent = event.clone({ body: responseBody });
    return of(clonedEvent);
  }

  private handleRegularRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      switchMap((event: HttpEvent<any>) => this.handleRegularResponse(event)),
      catchError((error: HttpErrorResponse) => this.handleAesErrorResponse(error))
    );
  }

  private handleRegularResponse(
    event: HttpEvent<any>
  ): Observable<HttpEvent<any>> {
    return this._processHybridResponse(event);
  }

  private handleHybridErrorResponse(error: HttpErrorResponse): Observable<never> {
    if (
      error.error?.data &&
      typeof error.error?.data === "string"
    ) {
      return this.ecyService.decryptHybridBody(error.error.data).pipe(
        switchMap((decryptedBody) => {
          const decryptedError = new HttpErrorResponse({
            ...error,
            error: { ...error.error, data: decryptedBody },
          });
          return throwError(() => decryptedError);
        }),
        catchError((decryptError) => {
          return throwError(() => decryptError);
        })
      );
    }
    return throwError(() => error);
  }

  private handleAesErrorResponse(error: HttpErrorResponse): Observable<never> {
    if (
      error.error?.data &&
      typeof error.error?.data === "string"
    ) {
      return this.ecyService.decryptAesBody(error.error.data, error.url).pipe(
        switchMap((decryptedBody) => {
          const decryptedError = new HttpErrorResponse({
            ...error,
            error: { ...error.error, data: decryptedBody },
          });
          return throwError(() => decryptedError);
        }),
        catchError((decryptError) => {
          return throwError(() => decryptError);
        })
      );
    }
    return throwError(() => error);
  }

  private _processHybridResponse(event: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (
      event instanceof HttpResponse &&
      event.body &&
      typeof event.body.data === "string"
    ) {
      return this.decryptHybridResponseBody(event);
    }
    return of(event);
  }

  private _processAesResponse(
    event: HttpEvent<any>
  ): Observable<HttpEvent<any>> {
    if (
      event instanceof HttpResponse &&
      event.body &&
      typeof event.body.data === "string"
    ) {
      return this.decryptAesResponseBody(event);
    }
    return of(event);
  }

  private decryptAesResponseBody(event: HttpResponse<any>): Observable<HttpEvent<any>> {
    return this.ecyService.decryptAesBody(event.body.data, event.url).pipe(
      switchMap((decryptedBody) => {
        let responseBody;
        try {
          responseBody = JSON.parse(decryptedBody);
        } catch (e) {
          responseBody = decryptedBody;
        }
        const clonedEvent = event.clone({ body: responseBody });
        return of(clonedEvent);
      }),
      catchError((error) => {
        return of(event);
      })
    );
    
  }

}
