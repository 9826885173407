import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UtilsService } from "../services/utils.service";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor(private readonly _utilS: UtilsService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const idClient: string = this._utilS.getIdClient.getValue() ?? null;
    let reqClone = req;
    const token = sessionStorage.getItem("publicToken");
    const noToken = ["onboarding-web/security/auth", "/security/safe-data"].some((urlPart) =>
      req.url.includes(urlPart)
    );
    const notoken2 = req.url.includes("/assets/");
    const isExcluded = 
      req.url.includes("/security/appian-process") || 
      req.url.includes("/security/hybrid-security/onfido-process") || 
      req.url.includes("/Client/onboarding") || 
      (req.method === "POST" && req.url.includes("/Client/profile"));
    const termsPath = [
      "privacy-policy",
      "terms-and-conditions",
      "privacy-policy",
      "escrow",
    ];
    const isTerms = termsPath.find((path) => req.url.includes(path));
    const nameClientId = !!isTerms ? "appianClientId" : "clientId";
    const isAppianProcess = req.url.includes("/security/appian-process");
    const uploadDocument = req.url.includes("/Document/appian-upload-file");
    const isS3Url = req.url.includes('s3.amazonaws.com');
    // Si es una URL de S3, creamos una nueva petición limpia sin headers, estos es para las URL presignadas
    if (isS3Url) {
      const cleanReq = req.clone({
        headers: req.headers.delete('Accept')
          .delete('Content-Type')
          .delete('Access-Token')
          .delete('accept')
          .delete('content-type')
      });
      return next.handle(cleanReq);
    }

    // Si es una petición de uploadDocument, mantenemos los headers pero removemos clientId
    if (uploadDocument) {
      reqClone = reqClone.clone({
        setHeaders: {
          "Access-Token": token,
        },
      });
      return next.handle(reqClone);
    }

    // Para el resto de las peticiones, mantener la lógica original
    reqClone = reqClone.clone({
      setHeaders: {
        "accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        ...(isAppianProcess ? { "channel": "ONBOARDING" } : {}),
        ...(noToken || notoken2 ? {} : { "Access-Token": token }),
        ...(!isExcluded && idClient ? { [nameClientId]: String(idClient) } : {}),
      },
    });

    return next.handle(reqClone);
  }
}
