import { 
	HttpErrorResponse, 
	HttpEvent, 
	HttpHandler, 
	HttpInterceptor, 
	HttpRequest,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { Observable, catchError, throwError } from 'rxjs';
  import { ErrorHandleService } from '../services/error-handle.service';
  
  type HttpErrorConfig = {
	type: 'error';
	title: string;
	subtitle?: string;
	message?: string;
	button?: string;
	icon?: string;
	error_code?: string;
  };
  
  type ErrorConfigMap = {
	[key: number]: HttpErrorConfig;
	default: HttpErrorConfig;
  };
  
@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

	private errorConfig: ErrorConfigMap = {
	400: {
		type: 'error',
		icon: 'assets/icons/ic_error.svg',
		title: 'MODAL_LABELS.TECHNICAL_ERROR',
		subtitle: 'MODAL_LABELS.ERROR_400',
		button: 'BUTTONS_LABELS.ACCEPT',
	},
	401: {
		type: 'error',
		icon: 'assets/icons/ic_error.svg',
		title: 'MODAL_LABELS.TECHNICAL_ERROR',
		subtitle: 'MODAL_LABELS.ERROR_401',
		button: 'BUTTONS_LABELS.ACCEPT',
	},
	403: {
		type: 'error',
		icon: 'assets/icons/ic_error.svg',
		title: 'MODAL_LABELS.TECHNICAL_ERROR',
		subtitle: 'MODAL_LABELS.ERROR_403',
		button: 'BUTTONS_LABELS.ACCEPT',
	},
	404: {
		type: 'error',
		icon: 'assets/icons/ic_error.svg',
		title: 'MODAL_LABELS.TECHNICAL_ERROR',
		subtitle: 'MODAL_LABELS.ERROR_400', // Usando el mismo mensaje que 400
		button: 'BUTTONS_LABELS.ACCEPT',
	},
	500: {
		type: 'error',
		icon: 'assets/icons/ic_error.svg',
		title: 'MODAL_LABELS.TECHNICAL_ERROR',
		subtitle: 'MODAL_LABELS.TECHNICAL_ERROR_DESC',
		button: 'BUTTONS_LABELS.ACCEPT',
	},
	default: {
		type: 'error',
		icon: 'assets/icons/ic_error.svg',
		title: 'MODAL_LABELS.TECHNICAL_ERROR',
		subtitle: 'MODAL_LABELS.TECHNICAL_ERROR_DESC',
		button: 'BUTTONS_LABELS.ACCEPT',
	}
};

constructor(public errorHandle: ErrorHandleService) {}

	intercept(
		request: HttpRequest<unknown>, 
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
		catchError((err: HttpErrorResponse) => {
			if (err) {
			const errorStatus = err.status as keyof typeof this.errorConfig;
			const config = { 
				...(this.errorConfig[errorStatus] || this.errorConfig.default) 
			};

			// Si hay un mensaje específico del backend, lo usamos
			if (err.error?.message) {
				config.subtitle = err.error.message;
			}

			// Si hay un código de error específico
			if (err.error?.code) {
				config.error_code = err.error.code;
			}

			// Si hay información adicional
			if (err.error?.additionalInfo) {
				config.message = err.error.additionalInfo;
			}

			// Si es un error de validación específico
			if (err.error?.type === 'validation') {
				config.title = 'MODAL_LABELS.VALIDATION_ERROR';
				config.subtitle = 'MODAL_LABELS.VALIDATION_ERROR_DESC';
			}

			// Si es un error de OTP
			if (err.error?.type === 'otp') {
				config.title = 'MODAL_LABELS.TECHNICAL_ERROR';
				config.subtitle = 'MODAL_LABELS.INVALID_OTP_DESC';
			}

			this.errorHandle.show(config);
			return throwError(() => err);
			}
			return throwError(() => err);
		})
		);
	}
}