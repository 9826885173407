/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CommonModule, NgIf } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

// Permissions
import { NgxPermissionsModule } from 'ngx-permissions';


import { UpperCaseDirective } from './core/directives/upper-case.directive';
import { LowerCaseDirective } from './core/directives/lower-case.directive';
import { TrimValueDirective } from './core/directives/trim-value.directive';
import { OnlyLettersDirective } from './core/directives/only-letters.directive';
import { OnlyNumbersDirective } from './core/directives/only-numbers.directive';
import { NgModule } from '@angular/core';

export const SHARED_MODULES = [
	FormsModule,
	ReactiveFormsModule,
	HttpClientModule,
	RouterModule,
	CommonModule,
	NgxPermissionsModule,
	NgIf,
];

// Directives
export const DIRECTIVES = [
	UpperCaseDirective,
	LowerCaseDirective,
	TrimValueDirective,
	OnlyLettersDirective,
	OnlyNumbersDirective,
];
@NgModule({
	declarations: [...DIRECTIVES],
	exports: [...DIRECTIVES],
})
export class SharedModule {}
