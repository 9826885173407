import {
	HttpErrorResponse,
	HttpParams,
} from "@angular/common/http";
import { computed, Injectable, signal } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { Browser, Device, OS, Network } from "@app/core/models/deviceType.interface";
import { FormGroup } from "@angular/forms";

@Injectable({
	providedIn: "root",
})
export class UtilsService {
	private typeSendMethod: number = 1;
	private existOnboardingSubject = new BehaviorSubject<boolean>(false);

	private userNameSubject = new BehaviorSubject<string>('');
	private documentTypeMetamap: BehaviorSubject<string> = new BehaviorSubject<string>("");
	private idAccountObservable: BehaviorSubject<string> = new BehaviorSubject<string>("1");
	private emailClientObservable: BehaviorSubject<string> = new BehaviorSubject<string>("");
	private cryptoNumberObservable: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private idClientObservable$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	private statusRequestObservable: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private productNumberObservable: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private countryClientObservable: BehaviorSubject<string> = new BehaviorSubject<string>("");
	private configAccessObservable: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	private cryptoActivitieObservable: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private declarationClientObservable: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private documentTypeClientObservable: BehaviorSubject<string> = new BehaviorSubject<string>("");
	private documentNumberClientObservable: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private agreementsTermsObservable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private countryResidentClientObservable: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	private hasExistUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	private hasCryptoUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	private isUpdateClient: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	

	private clientId = signal('');
	clientID = computed(() => this.clientId());

	objValidateUserOnline: {
		$type: string;
		TokenCrypto: string;
		UserName: string;
		DocumentNumber: string;
		DocumentType: { $type: string; DocumentTypeId: number };
	};

	objDeviceType: {
		browser: Browser;
		device:  Device;
		os:      OS;
		network: Network;
		screen:  Screen;
	} 

	get userNameObservable() {
		return this.userNameSubject.asObservable();
	}

	set userName(name: string) {
		if (name) {
			this.userNameSubject.next(name);
		}
	}

	// Obtener el nombre del usuario directamente
	getUserNameValue(): string {
		return this.userNameSubject.value;
	}

	get getExistOnboardingObservable(): Observable<boolean> {
        return this.existOnboardingSubject.asObservable();
    }

    set setExistOnboardingObservable(value: boolean) {
        this.existOnboardingSubject.next(value);
    }

	get getIdClientObservable() {
		return this.idClientObservable$.asObservable();
	}

	get getIdClient() {
		return this.idClientObservable$;
	}

	set setIdClientObservable(data: string) {
		this.clientId.set(data)
		this.idClientObservable$.next(data);
	}

	get getSharedTypeAccountObservable() {
		return this.idAccountObservable.asObservable();
	}

	set setSharedTypeAccountObservableData(data: string) {
		this.idAccountObservable.next(data);
	}

	get sharedEmailObservable() {
		return this.emailClientObservable.asObservable();
	}

	get getEmailObservableValue() {
		return this.emailClientObservable.value;
	}

	set sharedEmailObservableData(data: string) {
		this.emailClientObservable.next(data);
	}

	get sharedDocumentObservable() {
		return this.documentTypeClientObservable.asObservable();
	}

	set setDocumentTypeObservable(data: string) {
		this.documentTypeMetamap.next(data);
	}

	get getDocumentTypeObservable() {
		return this.documentTypeMetamap.asObservable();
	}

	getDocumentTypeObservableValue(): string {
		return this.documentTypeMetamap.value;
	}

	set sharedDocumentObservableData(data: string) {
		this.documentTypeClientObservable.next(data);
	}

	get sharedDocumentNumberObservable() {
		return this.documentNumberClientObservable.asObservable();
	}

	set sharedDocumentNumberObservableData(data: number) {
		this.documentNumberClientObservable.next(data);
	}

	get sharedProductNumberObservable(): Observable<number> {
		return this.productNumberObservable.asObservable();
	}

	set sharedProductNumberObservableData(data: number) {
		this.productNumberObservable.next(data);
	}

	get sharedCryptoNumberObservable() {
		return this.cryptoNumberObservable.asObservable();
	}

	set sharedCryptoNumberObservableData(data: number) {
		this.cryptoNumberObservable.next(data);
	}

	get sharedDeclarationObservable() {
		return this.declarationClientObservable.asObservable();
	}

	set sharedDeclarationObservableData(data: number) {
		this.declarationClientObservable.next(data);
	}

	get activityCryptoObservable() {
		return this.cryptoActivitieObservable.asObservable();
	}

	set activityCryptoObservableData(data: number) {
		this.cryptoActivitieObservable.next(data);
	}

	get sharedAgreementsObservable() {
		return this.agreementsTermsObservable.asObservable();
	}

	set sharedAgreementsObservableData(data: boolean) {
		this.agreementsTermsObservable.next(data);
	}

	get statusObservable() {
		return this.statusRequestObservable.asObservable();
	}

	set statusObservableData(data: number) {
		this.statusRequestObservable.next(data);
	}

	get sharedCountryClientObservable() {
		return this.countryClientObservable.asObservable();
	}

	set sharedCountryClientObservableData(data: string) {
		this.countryClientObservable.next(data);
	}

	get sharedCountryResidentClientObservable() {
		return this.countryResidentClientObservable.asObservable();
	}

	set sharedCountryResidentClientObservableData(data: number) {
		this.countryResidentClientObservable.next(data);
	}

	set setTypeSendMethodCard(typeSend: number) {
		this.typeSendMethod = typeSend;
	}

	get getTypeSendMethodCard(): number {
		return this.typeSendMethod;
	}

	set setHasExistUser(data: boolean) {
		this.hasExistUser.next(data);
	}

	get getHasExistUser() {
		return this.hasExistUser.value;
	}

	set setHasCryptoUser(data: boolean) {
		this.hasCryptoUser.next(data);
	}

	get getHasCryptoUser() {
		return this.hasCryptoUser.value;
	}

	get getIsUpdateClient(): boolean {
		return this.isUpdateClient.value;
	}

	set setIsUpdateClient(data: boolean) {
		this.isUpdateClient.next(data);
	}

	get configAccess(): Observable<string> {
		return this.configAccessObservable.asObservable();
	}

	set configAccess(value: string) {
		this.configAccessObservable.next(value);
	}
	
	getConfigAccessValue(): string {
		return this.configAccessObservable.value;
	}

	public clearAppState(): void {
		this.configAccessObservable.next(null);
		// optionally clear other state variables as needed
	}

	public buildValidateUser(document: string, documentTypeId: number): void {

		this.objValidateUserOnline = {
			$type:
				"Tailored.ICBanking.UIProcess.MethodParameters.Framework.CryptoCustom.ValidateUserDataIn, Tailored.ICBanking.UIProcess",
			TokenCrypto:
				"XXXXXXXSXSXSXSXSXSXSXSXSXSXXSPLPLXPLPSLXPSLXPSLSPLPLSPLSPLPLSX",
			UserName: " ",
			DocumentNumber: document,
			DocumentType: {
				$type:
					"Infocorp.UIProcess.Entities.Framework.Common.DocumentType, Infocorp.UIProcess.Entities",
				DocumentTypeId: documentTypeId,
			},
		};
	}

	get getObjectValidateUserOnline() {
		return this.objValidateUserOnline;
	}

	public setDeviceType(deviceType: any)  {
		this.objDeviceType = deviceType;
	}
	
	get getDeviceType() {
		return this.objDeviceType;
	}

	/**
	 * Maneja la entrada de zipCode para eliminar espacios en blanco
	 * @param event Evento de entrada
	 * @param form Formulario que contiene el campo zipCode
	 * @param fieldName Nombre del campo (por defecto 'zipCode')
	 */
	public handleZipCodeInput(event: Event, form: FormGroup, fieldName: string = 'zipCode'): void {
		const input = event.target as HTMLInputElement;
		if (input && input.value) {
			// Eliminar espacios en blanco
			const trimmedValue = input.value.trim();
			
			// Solo actualizar si es diferente para evitar ciclo infinito
			if (trimmedValue !== input.value) {
				input.value = trimmedValue;
				form.get(fieldName)?.setValue(trimmedValue);
			}
		}
	}

	/**
	 * Limpia el campo zipCode de espacios en blanco
	 * @param zipCode Valor del código postal
	 * @returns Código postal limpio
	 */
	public cleanZipCode(zipCode: any): string {
		if (zipCode === undefined || zipCode === null) {
			return '';
		}
		return typeof zipCode === 'string' ? zipCode.trim() : zipCode;
	}

	/**
	 * Handles response
	 * Método para manejar las respuestas de api.
	 * @param res
	 * @returns
	 */
	handleResponse(res: any) {
		switch (res.status) {
			case 200:
			case 201:
			case 204:
				return res.body || "unhandled response";
			default:
				return this.handleError(res);
		}
	}

	/**
	 * Handles error
	 * Método para controlar los errores desde api.
	 * @param error
	 * @returns
	 */
	handleError(error: HttpErrorResponse) {
		return of(error);
	}

	/**
	 * Sets query params
	 * Método para crear los parametros por url.
	 * @param pageIndex
	 * @param pageSize
	 * @returns
	 */
	setQueryParams(pageIndex: number, pageSize: number) {
		const params = new HttpParams()
			.set("offset", pageIndex)
			.set("limit", pageSize);
		return params;
	}
	
	setParams(idCountry: number, idProvince?: number): HttpParams {
		return new HttpParams()
			.set("countryId", idCountry)
			.set("provinceId", idProvince)
			.set("pageSize", "5000");
	}
}
