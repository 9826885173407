import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UtilsService } from '../services/utils.service';
import { of } from 'rxjs';
import { LoadingService } from '../services/loading.service';

export const ClientIdGuard: CanActivateFn = () => {
  const load: LoadingService = inject(LoadingService);
  const utilS = inject(UtilsService);
  const router = inject(Router);
  if(!utilS.getIdClient.getValue()){
    router.navigate(['']);
    load.reset();
    return of(false)
  }
  return true;
};
