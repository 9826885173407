import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { LoadingService } from '../services/loading.service';

@Injectable({
	providedIn: 'root'
})
export class LoadingInterceptor implements HttpInterceptor {
	constructor(private readonly _loadS: LoadingService) {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		this._loadS.show();
		return next.handle(req).pipe(
			tap({
			  next: (event) =>{ if(event instanceof HttpResponse) this._loadS.hide(); },
			  error: () => this._loadS.hide(),
			})
		  );
	}
}
