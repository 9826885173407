import { environment } from "./environment";

export const apiUrls = {
	metamap: 			`${environment.api}/Client/verifications`,
	clientProfile: 		`${environment.api}/Client/hybrid-security/profile`,
	getProfile: 		`${environment.api}/Client/profile`,
	updateProfile: 		`${environment.api}/Client`,
	address: 			`${environment.api}/Client/address`,
	codeOtp: 			`${environment.api}/Client/hybrid-security/otp-1`,
	verifyOtp: 			`${environment.api}/Client/hybrid-security/otp-2`,
	financieInfo: 		`${environment.api}/Client/finance-info`,
	cryptoInfo: 		`${environment.api}/Client/crypto-info`,
	onboarding: 		`${environment.api}/Client/hybrid-security/onboarding`,
	clientReview: 		`${environment.api}/Client/client-review`,
	screener: 			`${environment.api}/Client/client-screener`,
	documents: 			`${environment.api}/Document/upload-document`,
	presignedUrl: 		`${environment.api}/Document`,
	deleteDocuments: 	`${environment.api}/Document/document`,
	cardInfo: 			`${environment.api}/Client/card-info`,
	getAccount:  		`${environment.api}/Client/hybrid-security/account`,
	validateUser: 		`${environment.api}/Client/framework/cryptocustom/validateuserdata`,
	enrollUser: 		`${environment.api}/Client/UserEnroll`,
	accessToken: 		`${environment.api}/onboarding-web/security/auth`,
	publicRSAKey: 		`${environment.api}/onboarding-web/security/safe-data/generate/values`,
	appianProcess:      `${environment.api}/security/appian-process`,
	onfido: 			`${environment.api}/security/hybrid-security/onfido-process`
};

export const EXCLUDE_API_ENCRYPT = [
	apiUrls.accessToken,
	apiUrls.publicRSAKey
]

export const FORCE_API_ENCRYPT = [apiUrls.getProfile]