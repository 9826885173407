import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	showLoadingSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	apiQueue: number[] = [];

	/**
	 * The function increments the pendingLoadings variable, and then emits a true value to the
	 * showLoadingSource observable
	 */
	show() {
		this.apiQueue.push(1);
		this.showLoadingSource.next(true);
	}

	/**
	 * If the number of pending loadings is less than or equal to zero, then we hide the loading
	 * indicator
	 */
	hide() {
		this.apiQueue.pop();
    	this.showLoadingSource.next( Number(this.apiQueue.length) != 0 );
	}

	reset(){
		this.apiQueue = [];
		this.showLoadingSource.next( Number(this.apiQueue.length) != 0 );
	}
}
