import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { accesTokenInterface } from "@app/core/models/accesToken.interface";
import { apiUrls } from "@environments/api-url";
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class GetTokenInitService {
  private readonly accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private readonly _http: HttpClient) {}

  public getInitTransaction(): Observable<boolean> {
    const tkn =  this.accessTokenSubject.getValue() ?? null;
    if(tkn) return of(true);
    return this._http.get<accesTokenInterface>(apiUrls.accessToken).pipe(
      map((accesToken) => {
        this.setAccesToken(accesToken["Access-Token"]);
        return !!accesToken
      }),catchError( () => of(false) )
    );
  }

  setAccesToken(accesToken: string): void {
    sessionStorage.setItem('publicToken', accesToken);
    this.accessTokenSubject.next(accesToken);
  }

  getAccesToken(): BehaviorSubject<string | null> {
    return this.accessTokenSubject;
  }
}
