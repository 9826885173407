import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import {
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from "@angular/router";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { LANG } from "@core/models/lang.enum";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgxPermissionsService } from "ngx-permissions";
import { AppState } from "@core/redux/app.reducer";
import { setRoute } from "@core/redux/router/router.actions";
import { select, Store } from "@ngrx/store";
import { CommonModule, ViewportScroller } from "@angular/common";
import { LoadingComponent } from "./core/components/loading/loading.component";
import { ErrorHandleService } from "./core/services/error-handle.service";
import { GetTokenInitService } from "./core/services/secureServices/get-token-init.service";
import { ScrollToTopOnNavigateDirective } from "src/shared/directives/scroll-to-top-on-navigate.directive";
import { DynamicModalComponent } from "./core/components/dynamic-modal/dynamic-modal.component";
import { HotjarService } from "./core/services/hotjar/hotjar.service";

export interface ModalProperties {
  icon : string;
  title: string;
  subtitle: string;
  button: string;
  buttonLabel: string;
}

@Component({
  selector: "vass-root",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [
    LoadingComponent,
    RouterOutlet,
    RouterModule,
    DynamicModalComponent,
    CommonModule,
    TranslateModule,
    ScrollToTopOnNavigateDirective
  ],
  providers: [GetTokenInitService],
})
export class AppComponent implements OnInit, AfterViewChecked, AfterViewInit {
  loader$: Observable<boolean>;
  browserLang: string | undefined;
  isViewModal$: any;
  public propertiesToRender: ModalProperties = {
    icon: 'assets/icons/check-circle.svg',
    title: 'MODAL_LABELS.TITLE_UPDATE_CLIENT',
    subtitle: 'MODAL_LABELS.UPDATE_CLIENT', 
    button: 'BUTTONS_LABELS.UPDATE',
    buttonLabel: 'BUTTONS_LABELS.UPDATE'
  }

  /**
   * Creates an instance of app component.
   *
   * @param cdRef
   * @param translate
   * @param router
   * @param store
   * @param permissionsService
   */
  constructor(
    private cdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private router: Router,
    private store: Store<AppState>,
    private permissionsService: NgxPermissionsService,
    private viewportScroller: ViewportScroller,
    public errorHandleService: ErrorHandleService,
    private readonly _hjS: HotjarService
  ) {
    translate.addLangs([LANG.ES, LANG.EN]);
    translate.setDefaultLang(LANG.ES);

    this.browserLang = translate.getBrowserLang();
    if (this.browserLang) {
      translate.use(
        this.browserLang.match(/es|en/) ? this.browserLang : LANG.ES
      );
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
  /**
   * AfterViewChecked
   * Método del ciclo de vida.
   */
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  /**
   * OnInit
   * Método del ciclo de vida donde seteamos el usuario de cas y observamos el cambio de ruta de la aplicaion.
   */
  ngOnInit() {    
    const perm = ["ADMIN"];
    this.permissionsService.loadPermissions(perm);
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe({
        next: (e: any) => {
          const url = e.urlAfterRedirects;
          this.store.dispatch(setRoute({ current: url }));
        },
      });
    this.loader$ = this.store.pipe(select((store: any) => store.ui.isLoading));
    this.isViewModal$ = this.errorHandleService.isViewModal;
  }

  ngAfterViewInit(): void {
    this._hjS.buildScript();
  }

}
