import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { EncryptionService } from '../services/EncryptionService/encryption-service.service';
import { catchError, map, of } from 'rxjs';
import { LoadingService } from '../services/loading.service';

export const PKeyGuard: CanActivateFn = () => {

  const encS: EncryptionService = inject(EncryptionService);
  const load: LoadingService = inject(LoadingService);
  const router: Router = inject(Router);

  return encS.getInitData().pipe(
    map( (isApproved: boolean) => {
      if (isApproved) return true;
      router.navigate(['']);
      load.reset();
      return false;     
    }),
    catchError(error => {
      console.error('Error al obtener la llave:', error);
      router.navigate(['']);
      load.reset();
      return of(false);
    })
  );

};
