import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { environment as ENV } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class HotjarService {

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  buildScript() {
    const windowObject = this._document.defaultView || window;
    const documentObject = this._document;
    const scr = documentObject.getElementById("hotjar-script") || null;
    if (scr) return;
    setTimeout(() => {
      const settings = ENV.hotjar;
      const headElement = documentObject.getElementsByTagName("head")[0];
      const scriptElement = documentObject.createElement("script");
      windowObject["hj"] =
        windowObject["hj"] ||
        function () {
          windowObject["hj"].q = (windowObject["hj"].q || []).push(arguments);
        };
      windowObject["_hjSettings"] = settings;
      scriptElement.async = true;
      scriptElement.src = `https://static.hotjar.com/c/hotjar-${settings.hjid}.js?sv=${settings.hjsv}`;
      scriptElement.id = "hotjar-script";
      headElement.appendChild(scriptElement);
    }, 3000);
  }
}
