import { DialogModule } from 'primeng/dialog';
import { ErrorHandleService, ModalConfig } from './../../services/error-handle.service';
import { Component, EventEmitter, inject, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'vass-dynamic-modal',
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DialogModule, 
    TranslateModule
  ]
})
export class DynamicModalComponent implements OnInit, OnDestroy {
  @Input() icon: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() button: string;
  @Input() buttonLabel: string;
  @Input() closeModal: boolean;

  @Output() onClose = new EventEmitter<void>();

  public modalConfig: ModalConfig | null = null;
  private subscription = new Subscription();

  private errorHandleService = inject(ErrorHandleService);

  ngOnInit(): void {
    this.subscription.add(
      this.errorHandleService.modalConfig.subscribe(config => {
        if (config) {
          this.modalConfig = config;
          this.icon = config.icon || this.icon;
          this.title = config.title || this.title;
          this.subtitle = config.subtitle || this.subtitle;
          this.button = config.button || this.button;
        }
      })
    );
  }

  onContinueClicked(): void {
    const currentConfig = this.errorHandleService.modalConfig.getValue();
    if (currentConfig?.onButtonClick) {
      currentConfig.onButtonClick();
    }
    this.errorHandleService.hide();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}