import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ModalConfig {
    type: 'success' | 'error' | 'warning' | 'info';
    icon?: string;
    title?: string;
    subtitle?: string;
    button?: string;
    message?: string;
    error_code?: string;
    onButtonClick?: () => void;  // Agregamos esta función para ejecutar una acción al hacer click en el botón
}

@Injectable({
    providedIn: 'root',
})
export class ErrorHandleService {
    private defaultIcons = {
		success: 'assets/icons/check-circle.svg',
		error: 'assets/icons/ic_error.svg',
		warning: 'assets/icons/warning.svg',
		info: 'assets/icons/info.svg'
	};

	public isViewModal = new BehaviorSubject<boolean>(false);
	public modalConfig = new BehaviorSubject<ModalConfig | null>(null);

	show(config: Partial<ModalConfig>) {
		const type = config.type || 'error';

		const fullConfig: ModalConfig = {
			type,
			icon: config.icon || this.defaultIcons[type],
			title: config.title || `MODAL_LABELS.${type.toUpperCase()}_TITLE`,
			subtitle: config.subtitle || config.message || `MODAL_LABELS.${type.toUpperCase()}_MESSAGE`,
			button: config.button || 'BUTTONS_LABELS.CONTINUE',
			message: config.message,
			error_code: config.error_code,
			onButtonClick: config.onButtonClick
		};

		this.modalConfig.next(fullConfig);
		this.isViewModal.next(true);
	}

	hide() {
		this.isViewModal.next(false);
		this.modalConfig.next(null);
	}
}