import { registerLocaleData } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withHashLocation } from '@angular/router';
import { APP_ROUTES } from '@app/app.routes';
import { VassMockApiModule } from '@core/lib/mock-api';
import { LANG } from '@core/models/lang.enum';
import { appReducers } from '@core/redux/app.reducer';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
registerLocaleData(localeEs, LANG.ES);
registerLocaleData(localeEn, LANG.EN);
export function httpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient);
}

// Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '../environments/environment';
import { EncryptionInterceptor } from './core/interceptors/encryption.interceptor';
import { ErrorHandlingInterceptor } from './core/interceptors/error-handling.interceptor';
import { HeadersInterceptor } from './core/interceptors/headers.interceptor';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { mockApiServices } from './mock-api';
import { SHARED_MODULES } from './shared';
import { firstValueFrom } from 'rxjs';
import { EncryptionService } from './core/services/EncryptionService/encryption-service.service';

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(APP_ROUTES, withHashLocation()),
		importProvidersFrom(
			VassMockApiModule.forRoot(mockApiServices),
			NgxPermissionsModule.forRoot(),
			TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					useFactory: httpLoaderFactory,
					deps: [HttpClient],
				},
			}),
			...SHARED_MODULES,
			StoreModule.forRoot(appReducers),
			StoreDevtoolsModule.instrument({
				maxAge: 25,
				logOnly: environment.production,
				autoPause: true,
			})
		),
		{ provide: APP_INITIALIZER, useFactory: initApp, deps: [EncryptionService], multi: true },
		{ provide: LOCALE_ID, useValue: LANG.ES },
		{ provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: EncryptionInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
	],
};

export function initApp(encryptionService: EncryptionService) {
	return () => {
		return firstValueFrom(encryptionService.loadAesKeyBase())
		.catch(error => {
			console.error('Error crítico al importar la clave:', error);
			return undefined;
		});
	};
}