import { Router, CanActivateFn } from "@angular/router";
import { inject } from "@angular/core";
import { of } from "rxjs";
import { LoadingService } from "../services/loading.service";
import { UtilsService } from "../services/utils.service";

export const AccessTknGuard: CanActivateFn = () => {
  const router = inject(Router);
  const utilsService = inject(UtilsService);
  const token = utilsService.getConfigAccessValue();
  const load: LoadingService = inject(LoadingService);
  if (!token) {
    router.navigate([""]);
    load.reset();
    return of(false);
  }
  return of(true);
};
