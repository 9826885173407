import { Routes } from '@angular/router';
import { ROUTES } from './pages/routes';
import { PKeyGuard as PGuard } from './core/guards/p-key.guard';
import { AccessTknGuard as TGuard } from './core/guards/access-tkn.guard';
import { ClientIdGuard as CGuard } from './core/guards/client-id.guard';

export const APP_ROUTES: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: ROUTES.WELCOME,
	},
	{
		path: ROUTES.WELCOME,
		loadComponent: () => import('./pages/welcome/welcome.component').then((m) => m.WelcomeComponent),
		runGuardsAndResolvers: 'paramsOrQueryParamsChange',
		canActivate: [PGuard]
	},
	{
		path: ROUTES.OTP,
		loadComponent: () => import('./pages/send-otp/send-otp.component').then((m) => m.SendOtpComponent),
		canActivate: [PGuard]
	},
	{
		path: ROUTES.VERIFY,
		loadComponent: () => import('./pages/verify-identity/verify-identity.component').then((m) => m.VerifyIdentityComponent),
		canActivate: [PGuard, TGuard, CGuard],
	},
	{
		path: ROUTES.VERIFIDATA,
		loadComponent: () => import('./pages/verification-data/verification-data.component').then((m) => m.VerificationDataComponent),
		canActivate: [PGuard, TGuard, CGuard],
	},
	{
		path: ROUTES.EMPLOYMENT,
		loadComponent: () => import('./pages/employment-and-address/employment-and-address.component').then((m) => m.EmploymentAndAddressComponent),
		canActivate: [PGuard, TGuard, CGuard],
	},
	{
		path: ROUTES.FINANCIAL,
		loadComponent: () => import('./pages/financial-data/financial-data.component').then((m) => m.FinancialDataComponent),
		canActivate: [PGuard, TGuard, CGuard],
	},
	{
		path: ROUTES.DOCUMENTS,
		loadComponent: () => import('./pages/loading-documents/loading-documents.component').then((m) => m.LoadingDocumentsComponent),
		canActivate: [PGuard, TGuard, CGuard],
	},
	{
		path: ROUTES.CRYPTO_INFO,
		loadComponent: () => import('./pages/crypto-activities/crypto-activities.component').then((m) => m.CryptoActivitiesComponent),
		canActivate: [PGuard, TGuard, CGuard],
	},
	{
		path: ROUTES.DEBIT_CARD,
		loadComponent: () => import('./pages/debit-card/debit-card.component').then((m) => m.DebitCardComponent),
		canActivate: [PGuard, TGuard, CGuard],
	},
	{
		path: ROUTES.TERMS,
		loadComponent: () => import('./pages/terms/terms.component').then((m) => m.TermsComponent),
		canActivate: [PGuard, TGuard, CGuard],
	},
	{
		path: ROUTES.FINISHED,
		loadComponent: () => import('./pages/costumer/costumer-finished/costumer-finished.component').then((m) => m.CostumerFinishedComponent),
		canActivate: [PGuard]
	},
	{
		path: ROUTES.DOWNLOAD_APP,
		loadComponent: () => import('./pages/verification-data/download-screen/download-screen/download-screen.component').then((m) => m.DownloadScreenComponent),
		canActivate: [PGuard]
	},
	{
		path: ROUTES.NOT_ALLOWED,
		loadComponent: () => import('./pages/error/not-allowed/not-allowed.component').then( (m) => m.NotAllowedComponent),
	},
	{
		path: ROUTES.CONTACT,
		loadComponent: () => import('./pages/costumer/costumer-contact/costumer-contact.component').then((m) => m.CostumerContactComponent),
		canActivate: [PGuard]
	},
	{
		path: ROUTES.CONGRATS,
		loadComponent: () => import('./pages/debit-card/congrats/congrats.component').then((m) => m.CongratsComponent),
		canActivate: [PGuard]
	},
];
