<div class="card flex justify-content-center">
	<p-dialog
		[visible]="true"
		[modal]="true"
		[style]="{ width: '50vw' }"
		[draggable]="false"
		[closable]="false"
		[resizable]="true"
		[baseZIndex]="10000"
		[closeOnEscape]="false"
		[dismissableMask]="false"
		[breakpoints]="{ '960px': '80vw' }"
	>
	<div class="flex flex-column justify-content-center align-items-center">
		<img
			[src]="icon || modalConfig?.icon"
			[alt]="title || modalConfig?.title || 'Modal icon'"
			width="50px"
		/>
		<p
			class="mt-4"
			[innerHTML]="(title || modalConfig?.title) | translate"
		></p>

		<span class="my-4 text-center">
			{{ (subtitle || modalConfig?.subtitle || modalConfig?.message) | translate }}
		</span>

		<span *ngIf="modalConfig?.error_code" class="text-sm text-gray-500 mb-3">
			{{ modalConfig.error_code }}
		</span>

		<button
			(click)="onContinueClicked()"
			class="btn btn--primary"
			type="button"
		>
			{{ (button || modalConfig?.button || 'BUTTONS_LABELS.CONTINUE') | translate }}
		</button>
		</div>
	</p-dialog>
</div>
