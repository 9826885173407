import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, from, map, mergeMap, Observable, of, zip } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import * as forge from 'node-forge';
import { HttpClient } from '@angular/common/http';
import { GetTokenInitService } from '../secureServices/get-token-init.service';
import { environment as ENV } from '@environments/environment';
import { apiUrls } from '@environments/api-url';
import { EnvironmentDecoderService } from '../environment-decoder.service';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private readonly _$publicKey: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private readonly _$aesKey: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _clavePredefinida: CryptoKey | undefined;
  private _countService: number = 0;

  constructor(
    private readonly _http: HttpClient, 
    private readonly tknS: GetTokenInitService, 
    private readonly _envDecoder :EnvironmentDecoderService) {}

  generateRandomKey(): string {
    const actualKey = this._$aesKey.getValue();
    const cry = !actualKey ? CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Hex) : actualKey;
    this._$aesKey.next(cry);
    return cry;
  }

  encryptAES(data: string, sessionKey: string): string {
    const key = CryptoJS.enc.Utf8.parse(sessionKey);
    const hashedKey = CryptoJS.SHA256(key);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(data, hashedKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    const encryptedBytes = CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
    return encryptedBytes;
  }

  decryptAES(encryptedData: string, sessionKey: string): string {
    try {
      const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedData);
      const iv = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(0, 4));
      const ciphertext: any = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(4));  
      const key = CryptoJS.enc.Utf8.parse(sessionKey);
      const hashedKey = CryptoJS.SHA256(key);
      //@ts-ignore
      const decrypted = CryptoJS.AES.decrypt({ciphertext}, hashedKey, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
      return decryptedString;
    } catch (err: any) {
        console.error("Error during decryption:", err);
        return "";
    }
  }

  decryptAES2(encryptedData: string, sessionKey: string): string {
    try {
      const encryptedBytes = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));
      const iv = CryptoJS.lib.WordArray.create(encryptedBytes.slice(0, 4));
      const ciphertext: any = CryptoJS.lib.WordArray.create(encryptedBytes.slice(4));  
      const key = CryptoJS.enc.Utf8.parse(sessionKey);
      const hashedKey = CryptoJS.SHA256(key);
      //@ts-ignore
      const decrypted = CryptoJS.AES.decrypt({ciphertext}, hashedKey, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
      return decryptedString;
    } catch (err: any) {
        console.error("Error during decryption:", err);
        return "";
    }
  }
 
  encryptRSA(toEncrypt: string): string {
    try {
      const publicKey: forge.pki.rsa.PublicKey = forge.pki.publicKeyFromPem(this._$publicKey.getValue());
      const encrypted = publicKey.encrypt(forge.util.encodeUtf8(toEncrypt), 'RSA-OAEP');
      return forge.util.encode64(encrypted);
    } catch (err) {
        console.error("Error during decryption:", err);
        return '';
    }
  }

  encryptHybridBody(data: any, sessionKey: string) {
    console.log("🟢 Srv -> ", this._countService);
    ENV.showLogs && console.log("ORIGINAL Request", JSON.stringify(data));
    return this.encryptAES(JSON.stringify(data), sessionKey);
  }

  decryptHybridBody(data: string, url?: string): any {
    try {
      const sanitizeData = JSON.parse(this.decryptAES(data, this._$aesKey.getValue() ));
      ENV.showLogs && console.log("DECRYPT RESPONSE", sanitizeData, "url", url);
      this._countService++;
      return sanitizeData;
    } catch (error) {
      console.error("ERR DECRYPT BODY", error)      
    }
  }

  private _getKeyFromGateway(): Observable<boolean> {
    if( this._$publicKey.getValue() ) return of(true);
    return this._http.get(apiUrls.publicRSAKey).pipe(
      map((res: any) => { 
        if(typeof res?.message == "string"){
          const p_key: string = this._decryptBase64(res?.message);
          this._$publicKey.next(p_key);
          return true;
        }
        return false;
      }),catchError( () => of(false))
    );
  }
  
  private _decryptBase64(code64: string): string {
    const res = CryptoJS.enc.Base64.parse(code64);
    const parsedStr = res.toString(CryptoJS.enc.Utf8);
    return parsedStr;
  }

  getInitData(): Observable<boolean> {
    return this.tknS.getInitTransaction().pipe(
      mergeMap( (res) => { 
        return zip(
          of(res),
          this._getKeyFromGateway()
        )
      }),
      map( ([ session_tkn, public_key ])  => {
        ENV.production && console.log("VEREDICTO GUARD ->", public_key && session_tkn)
        if (public_key && session_tkn) return true;
        return false;     
      })
    )
  }

  exposePublicKey(){
    return this._$publicKey.getValue();
  }

  exposeAesKey(){
    return this._$aesKey.getValue();
  }

  loadAesKeyBase(): Observable<void> {
    const clavePredefinidaHex = this._envDecoder.configToken;
    const claveArrayBuffer = this._hexStringToArrayBuffer(clavePredefinidaHex);
    return from(
      window.crypto.subtle.importKey(
        'raw',
        claveArrayBuffer,
        { name: 'AES-GCM' },
        true,
        ['encrypt', 'decrypt']
      ).then(clave => {
        this._clavePredefinida = clave;
      }).catch(error => {})
    ).pipe(
      map(() => undefined)
    );
  }

  decryptAesBody(encrypted: string, url: string): Observable<any> {
    if (!this._clavePredefinida) {
      throw new Error('Clave de desencriptación no está disponible');
    }
    return from((async () => {
      const encryptedArray = Uint8Array.from(atob(encrypted), c => c.charCodeAt(0));
      const iv = encryptedArray.slice(0, 16);
      const aad = encryptedArray.slice(16, 32);
      const tag = encryptedArray.slice(32, 48);
      const cipher = encryptedArray.slice(48);

      const combinedCipherText = new Uint8Array(cipher.length + tag.length);
      combinedCipherText.set(cipher);
      combinedCipherText.set(tag, cipher.length);
      const decryptedArrayBuffer = await window.crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv: iv,
          additionalData: aad,
        },
        this._clavePredefinida!,
        combinedCipherText
      );
      const decryptedString = new TextDecoder().decode(decryptedArrayBuffer);
      try {
        ENV.showLogs && console.log("AES DECRYPT", decryptedString, url)
        return JSON.parse(decryptedString);
      } catch (e) {
        return decryptedString;
      }
    })());
  }

  private _hexStringToArrayBuffer(hexString: string): ArrayBuffer {
    const bytes = new Uint8Array(hexString.match(/[\da-f]{2}/gi)!.map(h => parseInt(h, 16)));
    return bytes.buffer;
  }

}
